<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-row @click="goDetail" class="whitebook-Item pointer">
    <v-col class="img-col" cols="12" lg="6" md="12">
      <div class="whitebook-Item_imgBox">
        <v-img
          cover
          class="whitebook-Item_imgBox_img"
          width="100%"
          aspect-ratio="1.777"
          :src="photoUrl"
        ></v-img>
      </div>
    </v-col>
    <v-col class="content-col" cols="12" lg="6" md="12">
      <div class="whitebook-Item_textBox d-flex flex-column justify-lg-space-between">
        <div>
          <div class="d-flex">
            <p class="whitebook-Item_textBox_date">{{ created_at }}</p>
            <p class="whitebook-Item_textBox_tag">{{ category_name }}</p>
          </div>
          <h1 class="whitebook-Item_textBox_title text-overflow-ep-1">
            {{ name }}
          </h1>
          <div v-html="content" class="whitebook-Item_textBox_content text-overflow-ep-3 text-overflow-ep-xl-5 word-break--break-word mb-4">

          </div>
        </div>
        <div class="d-flex whitebook-Item_textBox_moreBtn mb-2">
          <TypeB-btn :to="to">{{ $t("action.download.right-now") }}</TypeB-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import itemMixins from "@/components/page/indexItem.js";
export default {
  mixins: [itemMixins],
  props: {
    item: Object,
  },
  computed: {
    to() {
      if (!this.item) return "";
      return {
        name: "white-book-detail",
        params: {
          site_id: this.site_id,
          code: this.code,
        },
      };
    },
  },
  methods: {
    goDetail() {
      this.$router.push(this.to)
    },
  },
};
</script>
